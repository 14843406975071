@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400&display=swap);
:root {
  --color1: rgb(81, 132, 181);
  --color2: grey;
  --color-text: rgb(64, 64, 74);
}

.fullHeight {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

h4 {
  margin: 0 !important;
}

h3 {
  font-weight: 500;
}

a {
  color: "#FF6952" !important;
}
.parallax {
	background-image: url(/static/media/backgroundpink.6fc489b4.jpg);

	height: 100%;

	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
	width: 28px;
	height: 23px;
	left: 30px;
	top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: grey;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgb(53, 116, 176);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(0, 21, 41);;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item{
margin-top: 10px;
color:white
}

.menu-title{
margin-top: 10px;
color:#b8b7ad;
overflow: hidden;
display: inline-block;
text-overflow: ellipsis;

}

.menu-sub-item{
  margin-top:10px;
  color:white;
}

@page {
  size: A4;
  margin: 0;
  padding: 0;
}

@media print {

  body,
  html {
    width: 21cm !important;
    height: 29.7cm !important;
    margin: 0;
  }



  .print-preview {
    width: 18.5cm;
    height: 27.7cm;
    display: block;
  }

  .watermark {
    position: fixed;
    display: block;
    counter-increment: page;
    page-break-after: always;
  }

  .page-header {
    position: fixed;
    background-color: white;
    border-bottom: 1px solid black;

    top: 0;
    width: 18.5cm;

    -webkit-transform: translate(-50%, 00%);

            transform: translate(-50%, 00%);
    left: 50%;
    padding-top: 15px;
    padding-bottom: 10px;

    /*page-break-after: always;*/
  }

  .page-footer {
    position: fixed;
    background-color: white;
    bottom: 0;
    width: 18.5cm;
    height: 1.5cm;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    left: 50%;
    /*page-break-after: always;*/
    border-top: 1px solid black;
  }

  .page-content {
    position: relative;
    padding-top: 3cm;
    left: 2.6cm;
    width: 16cm;

  }

  .newPage {
    clear: both;
    display: block;
    page-break-after: always;

  }

  #pageFooter {
    display: table-footer-group;
  }

  #pageFooter:before {
    counter-increment: page;
    content: counter(page) "/" counter(pages);
  }


}

@media screen {
  .watermark {
    display: none;
  }

  .hideOnScreen {
    display: none;
  }

}

.print-preview {
  background-color: white;
}


.paiement-bloc {
  margin-left: auto;
  margin-right: 0;
}

.font {
  font-family: 'JetBrains Mono', monospace;
  font-weight: regular;

}

.border {
  border: 1px solid black;
  border-radius: 7px;
}

.borderBot {
  border-bottom: 1px solid black;
}

.borderLeft {
  border-left: 1px solid black;
}

.bold {
  font-weight: bold;
}

/* FLEX */
.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-bottom {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.column-bottom-space {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-content: space-between;
}

.title-bloc {
  background-color: #D3D3D3;
  color: black;
  padding: 8px;
}

.title {
  font-size: 1.3em;
  text-transform: uppercase;
  font-family: 'Oswald';
  text-transform: 'uppercase';
}

.center {
  text-align: center;
}

.right {
  text-align: end;
}

.small {
  font-size: 0.87em;
}

.x-small {
  font-size: 0.8em;
}

.xx-small {
  font-size: 0.7em;
}

.justify {
  text-justify: inter-word;
}

.big {
  font-size: 1.1em;
}

.x-big {
  font-size: 2em;
}

.mar10 {
  margin: 10px;
}

.marBot20 {
  margin-bottom: 20px;
}

.marBot10 {
  margin-bottom: 10px;
}

.marBot30 {
  margin-bottom: 30px;
}

.marBot5 {
  margin-bottom: 5px;
}

.marR5 {
  margin-right: 5px;
}

.marTop5 {
  margin-top: 5px;
}

.pad10 {
  padding: 10px;
}

.pad20 {
  padding: 20px;
}

.pad5 {
  padding: 5px;
}

.up {
  text-transform: uppercase;
}

.wtab1 {
  width: 1.3cm;
}

.wtab2 {
  width: 2cm;
}

.wtab3 {
  width: 7.7cm;
}

.wtab4 {
  width: 0.8cm;
}

.wtab5 {
  width: 0.5cm;
}

.wtab6 {
  width: 11.9cm;
}

.top3 {
  padding-top: 3cm;
}



.grey {
  color: rgb(110, 110, 110);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
