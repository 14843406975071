@page {
  size: A4;
  margin: 0;
  padding: 0;
}

@media print {

  body,
  html {
    width: 21cm !important;
    height: 29.7cm !important;
    margin: 0;
  }



  .print-preview {
    width: 18.5cm;
    height: 27.7cm;
    display: block;
  }

  .watermark {
    position: fixed;
    display: block;
    counter-increment: page;
    page-break-after: always;
  }

  .page-header {
    position: fixed;
    background-color: white;
    border-bottom: 1px solid black;

    top: 0;
    width: 18.5cm;

    transform: translate(-50%, 00%);
    left: 50%;
    padding-top: 15px;
    padding-bottom: 10px;

    /*page-break-after: always;*/
  }

  .page-footer {
    position: fixed;
    background-color: white;
    bottom: 0;
    width: 18.5cm;
    height: 1.5cm;
    transform: translate(-50%, 0%);
    left: 50%;
    /*page-break-after: always;*/
    border-top: 1px solid black;
  }

  .page-content {
    position: relative;
    padding-top: 3cm;
    left: 2.6cm;
    width: 16cm;

  }

  .newPage {
    clear: both;
    display: block;
    page-break-after: always;

  }

  #pageFooter {
    display: table-footer-group;
  }

  #pageFooter:before {
    counter-increment: page;
    content: counter(page) "/" counter(pages);
  }


}

@media screen {
  .watermark {
    display: none;
  }

  .hideOnScreen {
    display: none;
  }

}

.print-preview {
  background-color: white;
}


.paiement-bloc {
  margin-left: auto;
  margin-right: 0;
}

.font {
  font-family: 'JetBrains Mono', monospace;
  font-weight: regular;

}

.border {
  border: 1px solid black;
  border-radius: 7px;
}

.borderBot {
  border-bottom: 1px solid black;
}

.borderLeft {
  border-left: 1px solid black;
}

.bold {
  font-weight: bold;
}

/* FLEX */
.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-bottom {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.column-bottom-space {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-content: space-between;
}

.title-bloc {
  background-color: #D3D3D3;
  color: black;
  padding: 8px;
}

.title {
  font-size: 1.3em;
  text-transform: uppercase;
  font-family: 'Oswald';
  text-transform: 'uppercase';
}

.center {
  text-align: center;
}

.right {
  text-align: end;
}

.small {
  font-size: 0.87em;
}

.x-small {
  font-size: 0.8em;
}

.xx-small {
  font-size: 0.7em;
}

.justify {
  text-justify: inter-word;
}

.big {
  font-size: 1.1em;
}

.x-big {
  font-size: 2em;
}

.mar10 {
  margin: 10px;
}

.marBot20 {
  margin-bottom: 20px;
}

.marBot10 {
  margin-bottom: 10px;
}

.marBot30 {
  margin-bottom: 30px;
}

.marBot5 {
  margin-bottom: 5px;
}

.marR5 {
  margin-right: 5px;
}

.marTop5 {
  margin-top: 5px;
}

.pad10 {
  padding: 10px;
}

.pad20 {
  padding: 20px;
}

.pad5 {
  padding: 5px;
}

.up {
  text-transform: uppercase;
}

.wtab1 {
  width: 1.3cm;
}

.wtab2 {
  width: 2cm;
}

.wtab3 {
  width: 7.7cm;
}

.wtab4 {
  width: 0.8cm;
}

.wtab5 {
  width: 0.5cm;
}

.wtab6 {
  width: 11.9cm;
}

.top3 {
  padding-top: 3cm;
}



.grey {
  color: rgb(110, 110, 110);
}