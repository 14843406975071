:root {
  --color1: rgb(81, 132, 181);
  --color2: grey;
  --color-text: rgb(64, 64, 74);
}

.fullHeight {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

h4 {
  margin: 0 !important;
}

h3 {
  font-weight: 500;
}

a {
  color: "#FF6952" !important;
}