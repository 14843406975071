.parallax {
	background-image: url("backgroundpink.jpg");

	height: 100%;

	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	}
