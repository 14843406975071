/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
	width: 28px;
	height: 23px;
	left: 30px;
	top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: grey;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgb(53, 116, 176);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(0, 21, 41);;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item{
margin-top: 10px;
color:white
}

.menu-title{
margin-top: 10px;
color:#b8b7ad;
overflow: hidden;
display: inline-block;
text-overflow: ellipsis;

}

.menu-sub-item{
  margin-top:10px;
  color:white;
}
